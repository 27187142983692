<template>
  <div class="vote">
    <v-card color="grey darken-3" class="mb-5" dark>
      <v-card-title>
        <h2>{{ ballot.name }}</h2>
      </v-card-title>
      <v-card-subtitle>
        {{ ballot.organization.name }} - {{ ballot.organization.description
        }}<br />
        Del: {{ formatDate(ballot.start_at) }} Al:
        {{ formatDate(ballot.end_at) }}
      </v-card-subtitle>
      <v-card-text>
        <div v-html="ballot.configuration_attributes.instructions"></div>
      </v-card-text>
    </v-card>
    <v-card
      v-for="question in ballot.questions"
      :key="question.id"
      class="mb-5 pa-5"
    >
      <h2 class="red--text">{{ question.name }}</h2>
      <p v-html="question.instructions"></p>
      <v-radio-group v-model="result[question.id]">
        <template v-slot:label> </template>
        <v-radio
          v-for="answer in question.answers"
          :key="answer.id"
          :value="answer.id"
        >
          <template v-slot:label>
            {{ answer.name }}
            <v-btn
              v-if="answer.additional_information"
              text
              color="primary"
              @click.stop="getoOtionDetail(answer)"
              >más información</v-btn
            >
          </template>
        </v-radio>
      </v-radio-group>
    </v-card>

    <v-btn
      color="grey darken-3"
      class="mx-auto mt-5"
      block
      rounded
      dark
      @click="verify"
      >{{ ballot.configuration_attributes.verify_selection_button_text }}</v-btn
    >

    <v-dialog v-model="dialog">
      <v-card class="pa-2">
        <v-card-title class="red--text mb-3" dark>{{
          ballot.configuration_attributes.verification_instructions
        }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-card
            v-for="item in preview"
            :key="item.questionId"
            class="pa-5 mb-5"
            color="grey lighten-5"
          >
            <h3>
              <v-icon color="red" left small>mdi-checkbox-blank-circle</v-icon>
              {{ item.questionName }} - {{ item.answerName }}
            </h3>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn width="40%" color="red" dark @click="dialog = false">{{
            ballot.configuration_attributes.back_button_text
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn width="40%" color="red" dark @click="submit">{{
            ballot.configuration_attributes.submit_button_text
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="optionDialog">
      <v-card class="pa-2">
        <v-card-title class="red--text" dark>
          {{ optionDetail.name }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <div v-html="optionDetail.additional_information"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="optionDialog = false">
            regresar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import votes from "@/services/votes";

export default {
  name: "VoteForm",
  components: {},
  props: ["election", "token"],
  data: () => ({
    dialog: false,
    optionDialog: false,
    result: {},
    optionDetail: {},
    preview: []
  }),
  mounted() {},
  computed: {
    ballot() {
      if (this.election != null) {
        return this.election;
      } else {
        return {
          name: null,
          organization: {
            name: null
          },
          questions: [
            {
              name: null,
              answers: [{ name: null, additional_information: null }]
            }
          ],
          configuration_attributes: {
            instructions: "Sin instrucciones",
            verify_selection_button_text: "verificar",
            verification_instructions: null,
            back_button_text: null,
            submit_button_text: null
          }
        };
      }
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [d, t] = date.split(" ");

      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year} ${t}`;
    },
    verify() {
      let selection = [];
      const vote = this.result;

      this.ballot.questions.forEach(function(question) {
        let answerId = vote[question.id] ?? null;
        let answerName = answerId
          ? question.answers.find(x => x.id == answerId).name
          : "No seleccionado";

        selection.push({
          questionId: question.id,
          questionName: question.name,
          answerId: answerId,
          answerName: answerName
        });
      });

      this.preview = selection;
      this.dialog = true;
    },
    submit() {
      let self = this;
      votes
        .create(this.result, this.token)
        .then(() => {
          self.$router.push("/thanks/" + self.election.id);
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response);
          }
        });
    },
    getoOtionDetail(answer) {
      this.optionDialog = true;
      this.optionDetail = answer;
    }
  }
};
</script>