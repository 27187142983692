<template>
  <div>
    <v-row>
      <v-col cols="11" md="7" class="mx-auto">
        <v-img src="@/assets/tuvoto.png"></v-img>
      </v-col>
    </v-row>
    <v-card flat v-if="valid">
      <v-card-text class="pt-5">
        <voteForm :election="election" :token="token"></voteForm>
      </v-card-text>
    </v-card>
    <v-card width="500" class="mx-auto mt-8" flat v-else>
      <v-card-title class="red--text">
        <h2>Advertencia!</h2>
      </v-card-title>
      <v-card-text>
        <p class="red--text display-3">{{ message }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <router-link :to="'/'" style="text-decoration: none; color: inherit;">
          <a>
            <h3>Continuar</h3>
          </a>
        </router-link>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import votes from "@/services/votes";
import voteForm from "@/components/votes/Form";

export default {
  name: "Vote",
  components: {
    voteForm
  },
  data: () => ({
    election: null,
    token: null,
    valid: false,
    message: null
  }),
  mounted() {
    let self = this;
    votes
      .get(this.$route.params.token)
      .then(response => {
        this.valid = true;
        self.election = response.data.election;
        self.token = response.data.token;
      })
      .catch(error => {
        this.valid = false;
        if (error.response.status == 422) {
          this.message = error.response.data.message;
        } else {
          console.log(error);
        }
      });
  }
};
</script>
